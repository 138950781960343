import React, { useState } from 'react';
import { Modal, Box } from '@mui/material';
import closeDark from "../../../assets/images/close-dark.svg";
import changePassword from "../../../assets/images/change-password.svg";
import { useUser } from '../../../state/UserContext';
import close from "../../../assets/images/close.svg";
import { changeUserPassword } from "../../../services/userService";
import CustomSnackbar from "../../../components/CustomSnackbar";
import PasswordIcon from "../../../assets/icons/closed_eye.svg";
import EyeIcon from "../../../assets/icons/opened_eye.svg";

const ChangePasswordModal = ({ open, handleCloseModal, theme, style }) => {
    const { setUser, userData } = useUser();
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [passwordSnackbar, setPasswordSnackbar] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleClose = () => {
        handleCloseModal();
        // Reset all fields
        setPasswordSnackbar(false);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setError("");
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (newPassword !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        try {
            const success = await changeUserPassword(userData, oldPassword, newPassword, setUser);
            if (success) {
                setPasswordSnackbar(true);
                setTimeout(() => {
                    handleClose();
                }, 1000);
            } else {
                setError("Failed to change password. Please verify again.");
            }
        } catch (error) {
            console.error("Error occurred when trying to update password", error);
            setError("An unexpected error occurred. Please try again later.");
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal"
        >
            <Box sx={style}>
                <div className="flex justify-end items-end">
                    <img
                        src={theme === "dark" ? closeDark : close}
                        className={`${theme === "dark" ? "w-4" : "w-6"}`} onClick={handleClose} alt="Close Modal"
                    />
                </div>
                <div className="flex justify-center items-center">
                    <img src={changePassword} className="w-20" alt="Change Password" />
                </div>
                <div className="flex justify-center items-center mb-2">
                    <h3 className="text-dark_black font-[600] text-[18px]">
                        Change Password
                    </h3>
                </div>
                <div className={`flex items-center justify-center text-[14px] font-[400px] ${theme === "dark" ? "text-white" : "text-[#475467]"}`}>
                    Ensure robust security by setting a strong password.
                </div>
                <form onSubmit={handleSubmit}>
                    {!userData.withGoogle &&
                        <div className="flex flex-col">
                            <label
                                htmlFor="oldPassword"
                                className={`text-[16px] mb-1 mt-6 font-[500] ${theme === "dark" ? "text-white" : "text-[#344054]"}`}
                            >
                                Old Password
                            </label>
                            <div className="flex input-container">

                                <input
                                    id="oldPassword"
                                    name="oldPassword"
                                    type={showPassword ? "text" : "password"}

                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    className={` w-full mt-1 sm:w-full md:w-full form-input-register rounded-[8px] border border-[#D0D5DD]   mb-[10px] px-3 focus:outline-none h-[44px] ${theme === "dark"
                                        ? "bg-[#4A4A4A]  text-white border-[#4A4A4A]"
                                        : "bg-[#FFF] text-dark"
                                        }`}
                                    required
                                />
                                <img
                                    src={showPassword ? EyeIcon : PasswordIcon}
                                    alt="Password Icon"
                                    onClick={togglePasswordVisibility}
                                    className="icon-auth cursor-pointer"
                                />

                            </div>
                        </div>}
                    <div className="flex flex-col mt-2">
                        <label
                            htmlFor="newPassword"
                            className={`text-[16px] mb-1 font-[500] ${theme === "dark" ? "text-white" : "text-[#344054]"}`}
                        >
                            New Password
                        </label>
                        <div className="flex input-container">
                            <input
                                id="newPassword"
                                name="newPassword"
                                type={showPassword ? "text" : "password"}

                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className={` w-full mt-1 sm:w-full md:w-full form-input-register rounded-[8px] border border-[#D0D5DD]   mb-[10px] px-3 focus:outline-none h-[44px] ${theme === "dark"
                                    ? "bg-[#4A4A4A]  text-white border-[#4A4A4A]"
                                    : "bg-[#FFF] text-dark"
                                    }`}
                                required
                            />
                            <img
                                src={showPassword ? EyeIcon : PasswordIcon}
                                alt="Password Icon"
                                onClick={togglePasswordVisibility}
                                className="icon-auth cursor-pointer"
                            />
                        </div>

                    </div>
                    <div className="flex flex-col">
                        <label
                            htmlFor="confirmPassword"
                            className={`text-[16px] mb-1 font-[500] ${theme === "dark" ? "text-white" : "text-[#344054]"}`}
                        >
                            Confirm Password
                        </label>
                        <div className="flex input-container">

                            <input
                                id="confirmPassword"
                                name="confirmPassword"
                                type={showPassword ? "text" : "password"}

                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className={` w-full mt-1 sm:w-full md:w-full form-input-register rounded-[8px] border border-[#D0D5DD]   mb-[10px] px-3 focus:outline-none h-[44px] ${theme === "dark"
                                    ? "bg-[#4A4A4A]  text-white border-[#4A4A4A]"
                                    : "bg-[#FFF] text-dark"
                                    }`}
                                required
                            />
                            <img
                                src={showPassword ? EyeIcon : PasswordIcon}
                                alt="Password Icon"
                                onClick={togglePasswordVisibility}
                                className="icon-auth cursor-pointer"
                            />
                        </div>

                    </div>
                    {error && <div className="text-red-500 text-sm">{error}</div>}
                    <div className="flex space-x-3 mb-4 mt-6 justify-center items-center w-full">
                        <button
                            className=
                            {`bg-[#D9D9D9] rounded-[10px] border text-black text-[16px] font-[500] px-4 py-3  w-full  mb-2 
                               
              `}
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="rounded-[10px] text-white text-[16px] font-[500] w-full px-4 py-3 mb-2"
                            style={{
                                backgroundColor:
                                    localStorage.getItem("theme-color") || userData.themeColor,
                            }}
                        >
                            Confirm
                        </button>
                    </div>
                    <CustomSnackbar
                        openSnackbar={passwordSnackbar}
                        closeSnackbar={() => {
                            setPasswordSnackbar(false);
                        }}
                        message="Password updated successfully."

                    />

                </form>


            </Box>

        </Modal >

    );
};

export default ChangePasswordModal;
