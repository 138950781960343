import React, { useState } from "react";
import { forgotPassword } from "../../services/authService";
import logo from "../../assets/images/logoDark.png";
import CustomSnackbar from "../../components/CustomSnackbar";

export default function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleForgotPassword = async (e) => {
        e.preventDefault();
        try {
            await forgotPassword(email);
            setOpenSnackbar(true);
        } catch (error) {
            console.error(error.message);
        }
    };

  return (
    <div className="w-full h-screen flex justify-center items-center bg-gray-100 background-image-login background-login">
      <div className="sm:w-1/2 w-full h-full flex flex-col mx-auto my-auto p-8">
        <div className="flex flex-col justify-center items-center">
          <img className="mt-10 lg:mt-12" src={logo} alt="Logo" />
        </div>

        <div className="flex flex-col items-center justify-center mt-8">
          <div className="flex justify-start items-start mt-12">
            <h1 className="login-title lg:mt-5 text-green-600 font-inter font-semibold  text-[25px] sm:text-[30px] leading-6 sm:leading-8">
              Reset Password
            </h1>
          </div>

          <form
            className="w-full lg:mt-2 sm:w-full md:w-full sm:block xs:px-12 py-12 space-y-4"
            onSubmit={handleForgotPassword}
          >
            <div>
              <label htmlFor="email" className="form-label">
                Email Address
              </label>
              <div className="flex input-container">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  className="w-full mt-1 mb-2 sm:w-full md:w-full form-input-register p-2 pr-10 border rounded-lg"
                  placeholder="Enter your email"
                  required
                />
              </div>
            </div>

            {error && <div className="text-red-500 mb-4">{error}</div>}
            {successMessage && (
              <div className="text-green-500 mb-4">{successMessage}</div>
            )}

            <div className="relative">
              <button
                type="submit"
                className="form-input-register mt-0 login-button rounded-lg p-2"
              >
                Send Reset Email
              </button>
            </div>
          </form>
        </div>
      </div>
      <CustomSnackbar
        openSnackbar={openSnackbar}
        closeSnackbar={() => {
          setOpenSnackbar(false);
        }}
        message="Password reset instructions sent to your email."
      />
    </div>
  );
}
