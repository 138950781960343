import React, { useState, useEffect, useContext } from "react";
import "../Styles.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { countries } from "../../constants";
import { useUser } from "../../state/UserContext";
import { ThemeContext } from "../../state/ThemeContext";
import { updateUserProfile, setAccountColor } from "../../services/userService";
import CustomSnackbar from "../../components/CustomSnackbar";
import uploadIcon from "../../assets/images/upload-icon.svg";
import uploadIconDark from "../../assets/images/upload-dark.svg";
import ChangePasswordModal from "../../components/modals/user/UpdatePasswordModal";
import help_icon from "../../assets/icons/help_icon.svg";
import help_icon_white from "../../assets/icons/help_icon_white.svg";
import HelpModal from "../../components/modals/help/helpModal";

const MAX_PDP_SIZE = 10000;

const SettingsPage = () => {
  const { setUser, userData } = useUser();
  const { theme } = useContext(ThemeContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const [pictureErrorSnackbar, setPictureErrorSnackbar] = useState(false);
  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = () => {
    setOpen(true);
  }
  const handleOpenHelp = () => {
    setHelpModal(true);
  }
  const handleCloseHelpModal = () => {
    setHelpModal(false);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    borderRadius: "16px",
    color: theme === "dark" ? "white" : "black",
    backgroundColor: theme === "dark" ? "#1E1E1E" : "#FFFFFF",
    boxShadow: 24,
    paddingTop: 3,
    paddingLeft: "30px",
    paddingRight: "30px",
  };
  if (window.matchMedia("(max-width: 600px)").matches) {
    style.width = "300px";
  }
  const [formData, setFormData] = useState({
    firstName: userData.firstName || "",
    lastName: userData.lastName || "",
    birthday: userData.birthday || "",
    theme: userData.themeColor || "",
    email: userData.email || "",
    phoneNumber: userData.phoneNumber || "",
    country: userData.country || "",
    password: userData.password || "",
    picture: userData.picture || "",
    city: userData.city || "",
    unitType: userData.unitType || "european"
  });
  const [selectedCountry, setCountry] = useState("");

  useEffect(() => {
    resetForm();
  }, [userData]);

  const resetForm = () => {
    setFormData({
      firstName: userData.firstName || "",
      lastName: userData.lastName || "",
      birthday: userData.birthday || "",
      theme: userData.themeColor || "",
      email: userData.email || "",
      phoneNumber: userData.phoneNumber || "",
      country: userData.country || "",
      picture: userData.picture || "",
      city: userData.city || "",
    });
    setCountry("");
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      if (file.size > MAX_PDP_SIZE) {
        setPictureErrorSnackbar(true);
      }
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
        setFormData({ ...formData, picture: e.target.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateProfile = () => {
    updateUserProfile(userData, formData, setUser)
      .then((user) => {
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Failed to update profile:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNumberChange = (value) => {
    setFormData({ ...formData, phoneNumber: value });
    countries.forEach((country) => {
      if (value && country.phone === value.slice(1, value.length)) {
        setCountry(country.label);
      }
    });
  };
  const colors = ["#168004", "#48CAE4", "#E63946", "#7B2CBF", "#000000"];

  const setTheme = (color) => {
    document.documentElement.style.setProperty("--theme-color", color);
    switch (color) {
      case "#168004":
        document.documentElement.style.setProperty(
          "--theme-color-light",
          "rgba(22, 128, 4, 0.65)"
        );
        document.documentElement.style.setProperty(
          "--theme-color-input",
          "#F7FBF7"
        );

        break;
      case "#48CAE4":
        document.documentElement.style.setProperty(
          "--theme-color-light",
          "#38A3A5"
        );
        document.documentElement.style.setProperty(
          "--theme-color-input",
          "#EDF6F9"
        );

        break;
      case "#E63946":
        document.documentElement.style.setProperty(
          "--theme-color-light",
          "#F94144"
        );
        document.documentElement.style.setProperty(
          "--theme-color-input",
          "#FEC5BB"
        );

        break;
      case "#7B2CBF":
        document.documentElement.style.setProperty(
          "--theme-color-light",
          "#C77DFF"
        );
        document.documentElement.style.setProperty(
          "--theme-color-input",
          "#F1D6FF"
        );

        break;
      case "#000000":
        document.documentElement.style.setProperty(
          "--theme-color-light",
          "#293241"
        );
        document.documentElement.style.setProperty(
          "--theme-color-input",
          "#E9ECEF"
        );

        break;
      default: ;
    }
  };

  const handleSetColor = (color) => {
    setAccountColor(color, userData, formData, setFormData, setTheme);
  };

  useEffect(() => {
    const currentColor = localStorage.getItem("user-color");
    setTheme(currentColor);
  }, []);

  return (
    <div className=" mx-[32px] mt-[36px]">
      <h2
        className={` text-[25px] font-[600] ${theme === "dark" ? "text-white" : "text-black"}`}>
        Profile Settings
      </h2>
      <section className="rounded-lg profile-section ">
        <div className="flex flex-col sm:flex-row items-center">
          <div className={`max-w-[130px] mt-8`}>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="image-upload"
              onChange={handleImageUpload}
            />
            <label htmlFor="image-upload">
              <div
                style={{
                  width: "110px",
                  height: "110px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  cursor: "pointer",
                  backgroundColor: "#EDF2F6",
                }}
              >
                {selectedImage || userData.picture ? (
                  <img
                    src={selectedImage || userData.picture}
                    alt="Profile"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                ) : (
                  <div
                    className="flex flex-col justify-center items-center bg-[#EDF2F6]"
                    style={{ width: "100%", height: "100%" }}
                  >
                    {/* <img
                      src={uploadIcon}
                      alt="Upload Icon"
                      className="picture p-4"
                    /> */}
                    {/* <p className={`text-black dark:text-white `}>
                      Upload your <br /> photo
                    </p> */}
                  </div>
                )}
              </div>
            </label>
          </div>
          <div className="flex flex-col lg:ml-6 ml-2 lg:mt-16 mt-8 justify-center items-center sm:justify-start sm:items-start">
            <div className="flex items-center">
              <img
                src={theme === "dark" ? uploadIconDark : uploadIcon}
                alt="upload-icon"
                onClick={() => document.getElementById('image-upload').click()}
                className="w-6"
              />
              <span className={`ml-2 ${theme === "dark" ? "text-white" : "text-[#344054]"}`} onClick={() => document.getElementById('image-upload').click()}>Upload photo</span>
            </div>
            <span className={`mt-2 ${theme === "dark" ? "text-white" : "text-[#344054]"}`}>{userData.email}</span>
          </div>

        </div>

        <form className="flex profile-form justify-between flex-wrap w-[100%] gap-[61px]">
          <div className="flex flex-col gap-[15px] text-left flex-1 sm:w-1/2">
            <div className="flex flex-col">
              <label
                htmlFor="firstName"
                className={` text-[16px] mb-1 font-[500] mt-8  ${theme === "dark" ? "text-white" : "text-[#8B8B8B]"
                  }`}
              >
                First Name
              </label>
              <input
                label="firstName"
                name="firstName"
                type="text"
                value={formData.firstName}
                onChange={handleChange}
                className={`rounded-[8px]  mb-[10px] px-3 focus:outline-none h-[44px] ${theme === "dark"
                  ? "bg-[#4A4A4A]  text-white"
                  : "bg-[#F5F5F5] text-dark"
                  }`}
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="phoneNumber"
                className={` text-[16px] mb-1 font-[500]  ${theme === "dark" ? "text-white" : "text-[#8B8B8B]"
                  }`}
              >
                Phone Number
              </label>
              <PhoneInput
                international
                value={formData.phoneNumber}
                onChange={handleNumberChange}
                country={selectedCountry}
                flags={{}}
                className={`rounded-[8px]  mb-[10px] px-3 focus:outline-none h-[44px] ${theme === "dark"
                  ? "bg-[#4A4A4A]  text-white"
                  : "bg-[#F5F5F5] text-dark"
                  }`}
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="theme"
                className={` text-[16px] mb-1 font-[500]  ${theme === "dark" ? "text-white" : "text-[#8B8B8B]"
                  }`}
              >
                Theme colors
              </label>
              <div className="flex gap-4 h-[54px] mt-2">
                {colors.map((color, i) => (
                  <div
                    key={i}
                    style={{ backgroundColor: color }}
                    className={`cursor-pointer w-[2rem] h-[2rem] rounded-full`}
                    onClick={() => handleSetColor(color)}
                  ></div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[15px] text-left flex-1 sm:w-1/2">
            <div className="flex flex-col">
              <label
                htmlFor="lastName"
                className={` text-[16px] mb-1   mt-8 font-[500]  ${theme === "dark" ? "text-white" : "text-[#8B8B8B]"
                  }`}
              >
                Last Name
              </label>
              <input
                label="lastName"
                name="lastName"
                type="text"
                value={formData.lastName}
                onChange={handleChange}
                className={`rounded-[8px]  mb-[10px] px-3 focus:outline-none h-[44px] ${theme === "dark"
                  ? "bg-[#4A4A4A]  text-white"
                  : "bg-[#F5F5F5] text-dark"
                  }`}
              />
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row">
                <label
                  htmlFor="unitType"
                  className={`text-[16px] mb-1 font-[500] ${theme === "dark" ? "text-white" : "text-[#8B8B8B]"}`}
                >
                  Unit Type
                </label>
                <img
                  src={theme === "dark" ? help_icon_white : help_icon}
                  className="w-5 h-5 ml-2 cursor-pointer"
                  onClick={handleOpenHelp}
                />
              </div>
              <select
                id="unitType"
                name="unitType"
                value={formData.unitType}
                onChange={handleChange}
                className={`rounded-[8px] mb-[10px] px-3 focus:outline-none h-[44px] ${theme === "dark" ? "bg-[#4A4A4A] text-white" : "bg-[#F5F5F5] text-dark"}`}
              >

                <option value="european">European standard</option>
                <option value="american">American standard</option>
              </select>

            </div>
            <div className="flex flex-col">
              <div className="text-[16px] text-[#EE1414] font-bold mt-8 underline cursor-pointer" onClick={handleOpen}>
                Change Password
              </div>
            </div>


          </div>
        </form>
        <div className="flex profile-btns justify-between mt-[52px] flex-wrap">
          <div className="w-full mb-2 lg:mb-0 lg:w-auto"></div>
          <div className="flex flex-wrap gap-[18px] w-full lg:w-auto">
            <button
                    className="bg-[#D9D9D9] rounded-[10px] px-[16px] py-[10px] w-full lg:w-28 text-black"
                    onClick={resetForm}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleUpdateProfile}
              className="rounded-[10px] text-white text-[16px] font-[500] px-4 py-3 w-full lg:w-28"
              style={{
                backgroundColor:
                  localStorage.getItem("theme-color") || userData.themeColor,
              }}
            >
              Update
            </button>
          </div>
        </div>

        <CustomSnackbar
          openSnackbar={pictureErrorSnackbar}
          closeSnackbar={() => {
            setPictureErrorSnackbar(false);
          }}
          message="Profile Picture size exceeds the limit. Please select a smaller picture."
        />

        <CustomSnackbar
          openSnackbar={snackbarOpen}
          closeSnackbar={() => {
            setSnackbarOpen(false);
          }}
          message="Profile updated successfully!"
          isError={true}
        />

        <ChangePasswordModal
          open={open}
          handleCloseModal={handleClose}
          theme={theme}
          style={style}
        />

        <HelpModal open={helpModal}
          handleCloseModal={handleCloseHelpModal}
          component="unitType"
          theme={theme}
          style={style}
        />
      </section>

    </div>
  );
};

export default SettingsPage;
