import React from 'react';
import { Modal, Box } from '@mui/material';
import closeDark from "../../../assets/images/close-dark.svg";
import close from "../../../assets/images/close.svg";

const HelpModal = ({ open, handleCloseModal, theme, style, component }) => {
    const handleClose = () => {
        handleCloseModal();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal"

        >
            <Box sx={style}>
                <div className="flex justify-end items-end">
                    <img
                        src={theme === "dark" ? closeDark : close}
                        className={`${theme === "dark" ? "w-4" : "w-6"}`} onClick={handleClose} alt="Close Modal"
                    />
                </div>

                <div className="flex justify-start items-center mb-2">
                    <h3 className="text-dark_black font-[600] text-[18px] mb-4">HELP</h3>
                </div>
                <div className={`flex items-start justify-start text-[15px] font-[400] ${theme === "dark" ? "text-white" : "text-[#475467]"}`}>
                    {component === "unitType" ? (
                        <p>
                            Unit types refer to how measurements are standardized.<br />
                            <strong> There are two main systems: </strong> <br />
                            - The metric system (used mostly in Europe and worldwide) and the imperial system (used mainly in the US).<br />
                            - In the metric system, measurements are based on meters, grams, and liters, while the imperial system uses feet, pounds, and gallons.<br />
                            <strong>American standards</strong> follow the imperial system and are overseen by groups like ANSI and NEMA. <br />
                            <strong>European standards  </strong>usually follow the metric system and are regulated by bodies like CEN and CENELEC.
                        </p>
                    ) :
                        component === "dashboard" ?
                            <p>
                                Every dashboard will be linked to <strong> a specific board. </strong> <br />
                                You can manage the <strong> sensors </strong> connected to this board, arrange your dashboard easily, and customize your  <strong> widgets</strong> as you wish.
                            </p>
                            :
                            (
                                <p>Default text</p>
                            )}
                </div>

                <div className="flex justify-end items-center mt-4">
                    <div className="flex space-x-3 mb-4 mt-6 justify-center items-center">
                
                        <button
                            className=
                            {`bg-[#D9D9D9] rounded-[10px] border text-black text-[16px] font-[500] px-4 py-3  w-full  mb-2`}
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default HelpModal;
