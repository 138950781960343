import React, { useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

import LightingChartConsole from "../../components/widgets/console-widgets/LightingWidget";
import OrientationChartConsole from "../../components/widgets/console-widgets/OrientationWidget";
import AccelerometerWidgetConsole from "../../components/widgets/console-widgets/AccelerometerWidget";
import TempWidgetConsole from "../../components/widgets/console-widgets/TempWidget";
import PressureAltitudeChartConsole from "../../components/widgets/console-widgets/PressureAltitudeChart";
import PresenceWidgetConsole from "../../components/widgets/console-widgets/PresenceWidget";
import UVChartConsole from "../../components/widgets/console-widgets/UVChart";
import { ThemeContext } from '../../state/ThemeContext';
import "../../pages/dashboard/Dashboard.css";
import no_data from "../../assets/images/no_realtime_data.svg";

const ChartsDashboard = () => {
  const [sensorType, setSensorType] = useState("");
  const [dataSensor, setDataSensor] = useState({});
  const { theme } = useContext(ThemeContext);
  // to track user login status
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const user = JSON.parse(urlParams.get("user"));
    if (user) {
      const token = urlParams.get("token");
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      setIsLoggedIn(true);
    }

    const token = localStorage.getItem("token");
    if (!token) {
      alert("Please log in first!");
      window.location.href = "/login";
      return;
    }

    setIsLoggedIn(true);
    const url = `${process.env.REACT_APP_SERVER_URL}`;
    const socket = io(url);

    // Error handling
    socket.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
    });

    socket.on("connect_timeout", () => {
      console.error("WebSocket connection timeout");
    });

    socket.on("disconnect", () => {
      console.error("WebSocket disconnected");
    });

    socket.on("message", (dataSensor) => {
      const parsedData = JSON.parse(dataSensor);
      setSensorType(getSensorType(parsedData));
      setDataSensor(parsedData);
    });

    return () => {
      socket.disconnect();
    };
  }, []);


  const getSensorType = (data) => {
    if (data.hasOwnProperty("Temperature")) return "temperature";
    if (data.hasOwnProperty("Pressure")) return "pressure";
    if (data.hasOwnProperty("Altitude")) return "altitude";
    if (data.hasOwnProperty("Lux")) return "Light";
    if (data.hasOwnProperty("presenceVal")) return "Motion";
    if (data.hasOwnProperty("X")) return "Accelerometer";
    if (data.hasOwnProperty("quatI")) return "Orientation";
    if (data.hasOwnProperty("UV")) return "Uv";
    return "";
  };
  const chartsColor = "#2ECA45";
  const renderWidget = () => {
    switch (sensorType) {
      case "temperature":
        return (
          <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4 mt-8 ml-8 mr-8">
            <TempWidgetConsole
              value={dataSensor.Temperature || 0}
              min={0}
              max={100}
              text={"Temperature"}
              color={chartsColor}
              sensor="temperature"
            />
            <TempWidgetConsole
              value={dataSensor.Humidity || 0}
              min={0}
              max={100}
              text={"Humidity"}
              color={chartsColor}
              sensor="temperature"
            />
          </div>
        );
      case "pressure":
        return (
          <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4 mt-8 ml-8 mr-8">
            <PressureAltitudeChartConsole
              value={dataSensor.Pressure || 0}
              sensor="pressure"
              color={chartsColor}
            />
            <PressureAltitudeChartConsole
              value={dataSensor.Altitude || 0}
              sensor="altitude"
              color={chartsColor}
            />
          </div>
        );
      case "Light":
        return (
          <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4 mt-8 ml-8 mr-8">
            <LightingChartConsole
              value={dataSensor || 0}
              sensor="Lighting"
              color={chartsColor}
            />
          </div>
        );
      case "Accelerometer":
        return (
          <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4 mt-8 ml-8 mr-8">
            <AccelerometerWidgetConsole
              value={dataSensor || 0}
              sensor="Accelerometer"
              color={chartsColor}
            />
          </div>
        );
      case "Orientation":
        return (
          <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4 mt-8 ml-8 mr-8">
            <OrientationChartConsole
              value={dataSensor || 0}
              sensor="Orientation"
              color={chartsColor}
            />
          </div>
        );
      case "Motion":
        return (
          <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4 mt-8 ml-8 mr-8">
            <PresenceWidgetConsole
              value={dataSensor.presenceVal || 0}
              color={chartsColor}
            />
          </div>
        );
      case "Uv":
        return (
          <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4 mt-8 ml-8 mr-8">
            <UVChartConsole value={dataSensor.UV || 0} color={chartsColor} />
          </div>
        );
      default:
        return (
          <>
            <div className="flex overflow-x-hidden text[#121211] font-semibold text-[18px] sm:text-[20px] gap-[16px] p-[12px] rounded-[8px] m-8 bg-[#F4F5FB] dark:bg-[#262626] dark:text-white items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="mt-[3px]"
              >
                <path
                  d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12.75 17.0625C12.75 17.1656 12.6656 17.25 12.5625 17.25H11.4375C11.3344 17.25 11.25 17.1656 11.25 17.0625V10.6875C11.25 10.5844 11.3344 10.5 11.4375 10.5H12.5625C12.6656 10.5 12.75 10.5844 12.75 10.6875V17.0625ZM12 9C11.7056 8.99399 11.4253 8.87282 11.2192 8.6625C11.0132 8.45218 10.8977 8.16945 10.8977 7.875C10.8977 7.58055 11.0132 7.29782 11.2192 7.0875C11.4253 6.87718 11.7056 6.75601 12 6.75C12.2944 6.75601 12.5747 6.87718 12.7808 7.0875C12.9868 7.29782 13.1023 7.58055 13.1023 7.875C13.1023 8.16945 12.9868 8.45218 12.7808 8.6625C12.5747 8.87282 12.2944 8.99399 12 9Z"
                  fill={localStorage.getItem("theme-color") || "#168004"}
                />
              </svg>
              <p className="max-w-[70%]">
                No data sent from Sensy32. Please branch your board, upload your code, and let us display your results.
              </p>
            </div>
            <div className="flex justify-center mt-20 w-[100%]">
              <div>
                <img src={no_data} alt="no data" />
                <p className={`mt-4 font-[700] text-[20px] ml-4 ${theme === "dark" ? "text-white " : "text-black"}`}>No Real time data found !</p>
              </div>
            </div>
          </>
        );
    }
  };
  return renderWidget();
};

export default ChartsDashboard;

//to test the sensor data
//http://localhost:4000/sensors/api/data?pressure=53&altitude=40
//http://localhost:4000/sensors/api/data?temperature=53&humidity=40
//http://localhost:4000/sensors/api/data?IR=53&Lux=40&Visible=1&Full=1
//motion:
//http://localhost:4000/sensors/api/data?presenceVal=2
//accelerometer:
//http://localhost:4000/sensors/api/data?X=3&Y=4&Z=5
//orientation:
//http://localhost:4000/sensors/api/data?quatI=2&quatJ=4&quatK=6&quatReal=1&quatRadianAccuracy=1
//http://localhost:4000/sensors/api/data?UV=1
