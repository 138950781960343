import React, { useEffect } from "react";

// widgets
import { useSearchParams } from "react-router-dom";
import { useUser } from "../../state/UserContext";
const Dashboard = () => {

  const handleOpen = () => {
    window.location.href = "/dashboards";
  };

  const { userData, setUserData } = useUser();
  const [searchParams] = useSearchParams();
  const { setUser } = useUser();

  useEffect(() => {
    const myParam = searchParams.get("data");
    if (myParam != null) {
      const { user, token } = JSON.parse(myParam);
      setUser(JSON.parse(myParam));
      setUserData(user);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      window.location.href = "/realtime-data";
    }
  }, []);
  return (
    <div>
      <div className="flex flex-col justify-center items-center h-[calc(100vh-95px)] overflow-y-scroll ">
        <h3 className="text-center text-text_black max-w-[350px] xs:max-w-[600px] text-[28px] font-[700] leading-[44px]">
          Hello{` ${userData.firstName}`}, Create New Dashboard{" "}
        </h3>
        <p className="text-center xs:max-w-[450px] max-w-[400px] text-[15px] text-[#787878] mt-[12px]">
          Start customizing your dashboard by adding or rearranging widgets.
          Your sensor data, your way.
        </p>

        <button
          className="hidden md:flex items-center gap-2 mt-5 bg-dark_green text-white rounded-[10px] sm:text-[16px] text-[15px] font-[500] px-[30px] py-[2px] sm:h-[45px] h-[45px] transition-all duration-75 active:scale-90"
          type="button"
          onClick={handleOpen}
          style={{
            backgroundColor: localStorage.getItem("theme-color") || "#168004",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 29 29"
            fill="none"
          >
            <path
              d="M25.0919 16.7236H16.7346V25.0809H25.0919V16.7236Z"
              stroke="white"
              strokeWidth="2.38778"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.9591 16.7236H3.60187V25.0809H11.9591V16.7236Z"
              stroke="white"
              strokeWidth="2.38778"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25.0919 3.59082H16.7346V11.948H25.0919V3.59082Z"
              stroke="white"
              strokeWidth="2.38778"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.9591 3.59082H3.60187V11.948H11.9591V3.59082Z"
              stroke="white"
              strokeWidth="2.38778"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Create Custom Dashboard</span>
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
