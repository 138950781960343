import React, { useContext, useState, useEffect } from 'react';
import { useUser } from '../../state/UserContext';
import { ThemeContext } from '../../state/ThemeContext';
import GeneralButton from '../../components/Button';
import crudIcon from '../../assets/images/crud-icon.svg';
import Bullet from '../../assets/images/Bullet.svg';
import DotsDark from '../../assets/images/dark-dots.svg';
import Edit from '../../assets/images/edit-icon.svg';
import no_dashboards from '../../assets/images/dashboard.svg';
import Delete from '../../assets/images/delete-icon.svg';
import Share from '../../assets/images/share-icon.svg';
import AddDashboardModal from '../../components/modals/dashboards/AddDashboardModal';
import InviteTeamModal from '../../components/modals/dashboards/InviteTeamModal';
import DeleteDashboardModal from '../../components/modals/dashboards/DeleteDashboardModal';
import { Link } from 'react-router-dom';
import { fetchDashboards, getUserByEmail, getUsersForDashboard } from "../../services/dashboardService";
import axios from 'axios';
import help_icon from "../../assets/icons/help_icon.svg";
import { fetchBoards } from '../../services/boardService';
import { CircularProgress } from '@mui/material';
import HelpModal from '../../components/modals/help/helpModal';
import help_icon_white from "../../assets/icons/help_icon_white.svg";
import EditDashboardModal from '../../components/modals/dashboards/EditDashboardModal';
import ListMembersModal from '../../components/modals/dashboards/ListMembersModal';
import CustomSnackbar from '../../components/CustomSnackbar';
import AddBoardWarningModal from '../../components/modals/sensors/AddBoardWarningModal';
export default function ListDashboards() {
    const { theme } = useContext(ThemeContext);
    const [showSuccessDelete, setShowSuccessDelete] = useState(false);
    const { userData } = useUser();
    // const handleOpen = () => setOpen(true);
    const handleOpen = () => {
        if (nbOfBoards === 0) {
            setOpenNoBoards(true);
        }
        else {
            setOpen(true);
        }
    };
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openShare, setOpenShare] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openListMembers, setOpenListMembers] = useState(false);
    const [defaultBoardId, setDefaultBoardId] = useState("");
    const [dashboards, setDashboards] = useState([]);
    const [userBoards, setUserBoards] = useState([]);
    const [members, setMembers] = useState([]);
    const [boards, setBoards] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sensor, setSensor] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [helpModal, setHelpModal] = useState(false);
    const [dashboardToEdit, setDashboardToEdit] = useState([]);
    const [nbOfBoards, setNbOfBoards] = useState(0);
    const [openNoBoards, setOpenNoBoards] = useState(false);

    //handle open edit dashboard modal
    const handleOpenEdit = (dashboard) => {
        setDashboardToEdit(dashboard);
        setOpenEdit(true)
    };
    useEffect(() => {
        const userNbBoards = parseInt(localStorage.getItem("nb_boards")) || 0;
        setNbOfBoards(userNbBoards);
    })

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { month: 'short', year: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    const [selectedDashboard, setSelectedDashboard] = useState("");
    //  Delete Board
    const handleOpenDelete = (dashboard) => {
        setSelectedDashboard(dashboard);
        setOpenDelete(true);
    };
    const handleOpenShare = (dashboard, members) => {
        setSelectedDashboard(dashboard);
        setMembers(members);
        setOpenShare(true);
    };
    const handleOpenListMembers = (dashboard, members) => {
        setSelectedDashboard(dashboard);
        setMembers(members);
        setOpenListMembers(true);
    };
    const handleCloseDelete = () => setOpenDelete(false);
    const handleCloseEdit = () => setOpenEdit(false);

    //Help Modal
    const handleOpenHelp = () => {
        setHelpModal(true);
    }
    const handleCloseHelpModal = () => {
        setHelpModal(false);
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "600px",
        borderRadius: "16px",
        color: theme === "dark" ? "white" : "black",
        backgroundColor: theme === "dark" ? "#1E1E1E" : "#FFFFFF",
        boxShadow: 24,
        paddingTop: 3,
        paddingLeft: "30px",
        paddingRight: "30px",
    };
    const style1 = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "450px",
        borderRadius: "16px",
        color: theme === "dark" ? "white" : "black",
        backgroundColor: theme === "dark" ? "#1E1E1E" : "#FFFFFF",
        boxShadow: 24,
        paddingTop: 3,
        paddingLeft: "30px",
        paddingRight: "30px",
    };
    if (window.matchMedia("(max-width: 600px)").matches) {
        style.width = "300px";
    }
    if (window.matchMedia("(max-width: 600px)").matches) {
        style1.width = "300px";
    }
    const LoadingIndicator = () => (
        <div className='flex justify-center items-center w-full h-full'>
            <CircularProgress
                style={{
                    color: localStorage.getItem("theme-color") || userData.themeColor,
                }}
            />
        </div>
    );
    const fetchMembersForDashboard = async (dashboardId) => {
        try {
            const members = await getUsersForDashboard(dashboardId);
            return members;
        } catch (error) {
            return [];
        }
    };
    const fetchData = async () => {
        try {
            setIsLoading(true);
            const dashboardsData = await fetchDashboards(userData.id);
            const boardsData = await fetchBoards(userData.id);
            setBoards(boardsData);
            const defaultId = boardsData[0].id;
            setDefaultBoardId(defaultId);
            await Promise.all(dashboardsData.map(async (dashboard) => {
                try {
                    const token = localStorage.getItem('token');
                    if (!token) {
                        console.error("Token is missing");
                        return;
                    }
                    const headers = {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ` + token

                    };
                    if (!dashboard) return;
                    const url = `${process.env.REACT_APP_SERVER_URL}/sensors/by-board/${dashboard.board}`;
                    const response = await axios.get(url, { headers });
                    const responseSensors = response.data;
                    if (responseSensors.length > 0) {
                        dashboard.numSensors = responseSensors.length;
                        setSensors(responseSensors);
                        const defaultSensorId = responseSensors[0].id;
                        setSensor(defaultSensorId);
                    }
                } catch (error) {
                    console.error("Error fetching sensors:", error);
                }
            }));

            await Promise.all(dashboardsData.map(async (dashboard) => {
                try {
                    // Fetch members for the current dashboard
                    const members = await fetchMembersForDashboard(dashboard.id);
                    // Update the dashboard with fetched members

                    if (members && Array.isArray(members)) {
                        const usersData = await Promise.all(members.map(async (email) => {
                            const response = await getUserByEmail(email);
                            return response;
                        }));
                        dashboard.members = usersData;
                    }
                } catch (error) {
                    console.error("Error fetching members for dashboard:", error);
                }
            }));
            setDashboards(dashboardsData);

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchBoardsRefresh = async () => {
        const boardsData = await fetchBoards(userData.id);
        setBoards(boardsData);
        const defaultId = boardsData.length > 0 ? boardsData[0].id : null;
        setDefaultBoardId(defaultId);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleDeleteDashboard = async (id) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error("Token is missing");
                return;
            }
            const headers = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ` + token

            };
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/dashboards/${id}`, { headers }
            );
            if (response.status == 200) {
                const updatedDashboards = dashboards.filter(dashboard => dashboard.id !== id);
                setDashboards(updatedDashboards);
                setShowSuccessDelete(true);
                handleCloseDelete();
            }
        } catch (error) {
            console.error("Error deleting board:", error);
        }
    };
    const handleUpdateDashboards = async () => {
        try {
            fetchData();
        } catch (error) {
            console.error("Error fetching updated dashboards:", error);
        }
    };
    const [dropdownStates, setDropdownStates] = useState({});
    const handleClose = async () => {
        setOpen(false);
    };
    const handleCloseShare = async () => {
        setOpenShare(false);
    };
    const handleCloseListMembers = async () => {
        setOpenListMembers(false);
    };
    const toggleDropdown = (index) => {
        setDropdownStates(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };
    return (
        <div className="mx-[32px] mt-[36px]">
            <div className="flex flex-col lg:flex-row  justify-between">
                <div className='flex flex-row md:flex-row items-start'>
                    <h2 className={`text-[25px] font-[600] ${theme === "dark" ? "text-white" : "text-black"}`}>
                        Dashboards
                    </h2>
                    <img src={theme === "dark" ? help_icon_white : help_icon} className="w-5 h-5 ml-2 mt-2 cursor-pointer" onClick={handleOpenHelp} />
                </div>
                <div className='flex md:items-end md:justify-end'>
                    <GeneralButton
                        onClick={handleOpen}
                        backgroundColor={
                            localStorage.getItem("theme-color") || userData.themeColor
                        }
                        className="mt-8 md:mt-0">
                        Create
                    </GeneralButton>
                </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-8">
                {isLoading ? (
                    <div className="col-span-3 flex justify-center items-center">
                        <LoadingIndicator />
                    </div>
                ) : dashboards.length === 0 ? (
                    <div className="col-span-3 flex flex-col justify-center items-center mt-12">
                        <img src={no_dashboards} alt="No Dashboards" className='mt-8' />
                        <div className={`mt-6 font-[700] text-[24px] ml-4 ${theme === "dark" ? "text-white " : "text-black"}`}> No Dashboard Yet </div>
                    </div>
                ) : (
                    dashboards.map((dashboard, index) => (

                        <div key={index} className={`rounded-[15px] shadow-md  relative h-[260px] w-full ${theme === "dark" ? "text-white bg-[#262626]" : "text-black"}`}>
                            <div className='min-h-[100px] max-h-[100px]'>
                                <div className="relative top-0 left-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="195" height="153" viewBox="0 0 195 153" fill="none" className='absolute left-0 top-0 rounded-tl-[15px]'>
                                        <circle opacity="0.3" cx="79.5" cy="38" r="115" fill="#D1E3E1" />
                                        <circle opacity="0.4" cx="79.5" cy="38" r="90" fill="#D1E3E1" />
                                    </svg>
                                    <img src={dashboard.logo} alt='edit-image' className='pl-[3.8rem] pt-8 w-35 h-20  justify-center items-center absolute' />

                                </div>
                                <div className='flex justify-between'>
                                    <div>
                                        <div className="absolute top-0 right-0">
                                            <img src={theme === "dark" ? DotsDark : crudIcon} alt='edit-image' className='w-9 p-2 cursor-pointer' onClick={() => toggleDropdown(index)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-between mt-10 p-4'>
                                <div>
                                    <Link to={`/dashboard/${dashboard.id}/${dashboard.board}`}>
                                        <p className={`font-[400px] lg:text-[18px] text-[14px] ${theme === "dark" ? "text-white " : "text-[#40464D]"}`}>{dashboard.name}</p>
                                    </Link>
                                    <div className='flex rounded-[8px] border border-[#ECEFF2] p-1 mt-4 w-[120px] lg:w-[150px]'>
                                        <img src={Bullet} className='lg:w-5 w-3' />
                                        <div className={`lg:ml-3 ml-1 font-[400px]] text-[16px]${theme === "dark" ? "text-white " : " text-[#606873]"}`}>
                                            {dashboard.numSensors ? `${dashboard.numSensors} Sensors` : 'No sensors'}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className={`font-[400px] lg:text-[14px] md:text-[14px] text-[12px] w-full${theme === "dark" ? "text-white " : " text-[#14213D]"}`}>{formatDate(dashboard.updated_at)}</p>
                                    {dashboard.members && dashboard.members.length > 0 ? (
                                        <div className='flex justify-end items-end mt-4' onClick={() => { handleOpenListMembers(dashboard.id, dashboard.members) }}>
                                            {dashboard.members.slice(0, 2).map((member, index) => (
                                                member.picture ? (
                                                    <img key={index} src={member.picture} alt='user-image' className='lg:w-8 md:w-8 w-6 rounded-[50%]' />
                                                ) : (
                                                    <div key={index} className="bg-green-400 lg:w-8 md:w-8 w-6 h-6 rounded-[50%] flex items-center justify-center text-white font-semibold text-sm">
                                                        {member.firstName.charAt(0)}
                                                    </div>
                                                )
                                            ))}
                                            {dashboard.members.length >= 3 && (
                                                <button className="lg:w-8 lg:h-8 md:w-8 md:h-8 p-1 w-6 h-6 rounded-[50%] bg-gray-200 flex items-center justify-center cursor-pointer text-black" >
                                                    +{dashboard.members.length - 2}
                                                </button>
                                            )}
                                        </div>
                                    ) : (
                                        <p className='pt-7'>No users found</p>
                                    )}
                                </div>
                            </div>
                            {dropdownStates[index] && (
                                <div className={`absolute top-10 right-0  shadow-md w-36 rounded-lg p-2 ${theme === "dark" ? "text-white bg-[#262626]" : "bg-white"}`}>
                                    <div className='cursor-pointer p-2 flex' onClick={() => { handleOpenEdit(dashboard) }}>
                                        <div className='rounded-[8px] bg-[#D2D2D240] p-2'>
                                            <img src={Edit} alt="Edit Icon" />
                                        </div>
                                        <span className='ml-4 mt-1'>Edit</span>
                                    </div>
                                    <div className='cursor-pointer p-2 flex' onClick={() => { handleOpenDelete(dashboard.id) }}>
                                        <div className='rounded-[8px] bg-[#D2D2D240] p-2'>
                                            <img src={Delete} />
                                        </div>
                                        <span className='ml-4 mt-1'>Delete</span>
                                    </div>
                                    <div className='cursor-pointer p-2 flex' onClick={() => { handleOpenShare(dashboard.id, dashboard.members) }} >
                                        <div className='rounded-[8px] bg-[#D2D2D240] p-2'>
                                            <img src={Share} />
                                        </div>
                                        <span className='ml-4 mt-1'>Share</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                )}

            </div>
            <AddDashboardModal
                open={open}
                handleClose={handleClose}
                style={style}
                userBoards={boards}
                setUserBoards={setUserBoards}
                handleUpdateDashboards={handleUpdateDashboards}
                defaultBoardId={defaultBoardId}
            />
            <EditDashboardModal
                open={openEdit}
                handleClose={handleCloseEdit}
                style={style}
                dashboard={dashboardToEdit}
                handleUpdateDashboards={handleUpdateDashboards}
            />

            <InviteTeamModal
                open={openShare}
                handleClose={handleCloseShare}
                style={style1}
                dashboardId={selectedDashboard}
                members={members}
                fetchMembers={fetchData}
            />
            <ListMembersModal
                open={openListMembers}
                handleClose={handleCloseListMembers}
                style={style1}
                dashboardId={selectedDashboard}
                members={members}
                fetchMembers={fetchData}

            />
            {/* Should add a board modal */}
            <AddBoardWarningModal
                open={openNoBoards}
                handleClose={() => setOpenNoBoards(false)}
                style={style}
                onBoardAdded={fetchBoardsRefresh}
            />
            <DeleteDashboardModal
                open={openDelete}
                handleClose={handleCloseDelete}
                style={style}
                handleDeleteDashboard={handleDeleteDashboard}
                dashboardId={selectedDashboard}
            />

            <CustomSnackbar
                openSnackbar={showSuccessDelete}
                closeSnackbar={() => setShowSuccessDelete(false)}
                message="Dashboard deleted successfully"
            />

            <HelpModal open={helpModal}
                handleCloseModal={handleCloseHelpModal}
                component="dashboard"
                theme={theme}
                style={style}
            />
        </div>
    );
}
