import React, { useState, useEffect } from "react";
import Layout from "./pages/Layout";
import { Route, Routes } from "react-router-dom";
import { UserProvider } from "./state/UserContext";
import { ThemeProvider } from "./state/ThemeContext";
import ComingSoon from "./pages/coming-soon/ComingSoon";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import LandingPage from "./pages/landing-page/LandingPage";
import BoardsPage from "./pages/boards/BoardsPage";
import HistoricalPage from "./pages/data-history/HistoricalPage";
import SettingsPage from "./pages/settings/SettingsPage";
import Dashboard from "./pages/dashboard/Dashboard";
import SensorsPage from "./pages/sensors/SensorsPage";
import CustomizeDashboard from "./pages/dashboard/CustomizeDashboard";
import Page404 from "./pages/not-found/Page404";
import ChartsDashboard from "./pages/charts-dashboard/ChartsDashboard";
import Documentation from "./pages/documentation/Documentation";
import ResetPassword from "./pages/reset-password/ResetPassword";
import ForgotPassword from "./pages/reset-password/ForgotPassword";
import Collaborators from "./pages/collaborators/Collaborators";
import ActivityLogs from "./pages/activity-logs/ActivityLogs";
import ListDashboards from "./pages/list-dashboards/ListDashboards";
import HomeDashboard from "./pages/home/HomeDashboard";

function App() {
  const [selectedThemeColor, setSelectedThemeColor] = useState("#00ff00");
  const [theme, setTheme] = useState(localStorage.getItem("themeMode"));
  const [user, setUser] = useState(false);

  useEffect(() => {
    const storedTheme = localStorage.getItem("themeMode");
    const tokenSaved = localStorage.getItem("token");
    if (tokenSaved) {
      setUser(true);
    }
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  return (
    <div className={`h-screen `}>
      <ThemeProvider>
        <UserProvider>

          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/home" element={user ? <Dashboard /> : <Login />} />
            {/* authenticated routes */}
            <Route
              path="/realtime-data"
              element={user ? <Layout page={<ChartsDashboard />} /> : <Login />}
            />

            <Route
              path="/dashboard/:dashboardId/:boardId"
              element={user ? <Layout page={<CustomizeDashboard />} /> : <Login />
              }
            />
            <Route
              path="/activity-log"
              element={
                user ? <Layout page={<ActivityLogs />} /> : <Login />
              }
            />
            <Route
              path="/collaborators"
              element={
                user ? <Layout page={<Collaborators />} /> : <Login />
              }
            />
            <Route
              path="/dashboards"
              element={
                user ? <Layout page={<ListDashboards />} /> : <Login />
              }
            />
            <Route
              path="/manage-boards"
              element={
                user ? (
                  <Layout
                    page={
                      <BoardsPage
                        selectedThemColor={selectedThemeColor}
                        onColorChange={selectedThemeColor}
                      />
                    }
                  />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/manage-sensors"
              element={user ? <Layout page={<SensorsPage />} /> : <Login />}
            />
            <Route
              path="/home-dashboard"
              element={user ? <Layout page={<HomeDashboard />} /> : <Login />}
            />
            <Route
              path="/history"
              element={user ? <Layout page={<HistoricalPage />} /> : <Login />}
            />
            <Route
              path="/settings"
              element={user ? <Layout page={<SettingsPage />} /> : <Login />}
            />
            <Route path="*" element={<Page404 />} />

            <Route path="/reset/:resetToken" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
        </UserProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
