import React, { useContext, useEffect, useState } from "react";
import { useUser } from '../../state/UserContext';
import { ThemeContext } from '../../state/ThemeContext';
import GeneralButton from '../../components/Button';
import DotsDark from '../../assets/images/dark-dots.svg';
import Delete from '../../assets/images/delete-icon.svg';
import crudIcon from '../../assets/images/crud-icon.svg';
import emailIcon from '../../assets/images/mail-icon.svg';
import emailIconDark from '../../assets/images/mail-icon-dark.svg';
import Filter from "../../components/Filter";
import { fetchDashboards, removeUserFromDashboard } from "../../services/dashboardService";
import AddMemberModal from "../../components/modals/dashboards/AddMemberModal";
import RemoveMemberModal from "../../components/modals/dashboards/RemoveMemberModal";

export default function Collaborators() {
    const { theme } = useContext(ThemeContext);
    const { userData } = useUser();
    const [dropdownStates, setDropdownStates] = useState({});
    const [dashboards, setDashboards] = useState([]);
    const [openAddMember, setOpenAddMember] = useState(false);
    const [members, setMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const handleCloseDelete = () => setOpenDelete(false);
    const handleOpen = () => setOpenAddMember(true);
    const handleOpenDelete = (member) => {
        setSelectedMember(member);
        setOpenDelete(true);
    };
    const handleCloseAdd = async () => {
        setOpenAddMember(false);
    };
    const style1 = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "450px",
        borderRadius: "16px",
        color: theme === "dark" ? "white" : "black",
        backgroundColor: theme === "dark" ? "#1E1E1E" : "#FFFFFF",
        boxShadow: 24,
        paddingTop: 3,
        paddingLeft: "30px",
        paddingRight: "30px",
    };
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "600px", // Default width for mobile screens
        borderRadius: "16px",
        color: theme === "dark" ? "white" : "black",
        backgroundColor: theme === "dark" ? "#1E1E1E" : "#FFFFFF",
        boxShadow: 24,
        paddingTop: 3,
        paddingLeft: "30px",
        paddingRight: "30px",
    };
    if (window.matchMedia("(max-width: 600px)").matches) {
        style1.width = "300px";
    }
    const toggleDropdown = (index) => {
        setDropdownStates(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };
    const fetchData = async () => {
        try {
            const dashboardsData = await fetchDashboards(userData.id);
            setDashboards(dashboardsData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleDeleteMember = async (id) => {
        try {
            await removeUserFromDashboard(id);
            handleCloseDelete();
        } catch (error) {
            console.error("Error deleting member:", error);
        }
    };

    return (
        <div className="mx-[32px] mt-[36px]">
            <div className="flex flex-col lg:flex-row  justify-between">
                <div className='flex flex-col md:flex-row items-start'>
                    <h2 className={`text-[25px] font-[600] ${theme === "dark" ? "text-white" : "text-black"}`}>
                        Team members
                    </h2>
                </div>
                <div className='flex md:items-end md:justify-end space-x-2'>
                    <Filter
                        dashboards={dashboards}
                        onClick={() => { }}
                    />
                    <GeneralButton
                        onClick={handleOpen}
                        backgroundColor={
                            localStorage.getItem("theme-color") || userData.themeColor
                        }
                        className="mt-8 md:mt-0"
                    >
                        New Member
                    </GeneralButton>
                </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 mt-8">
                {members.map((member, index) => (
                    <div key={index} className={`rounded-[15px] p-2 relative h-[235px] w-full ${theme === "dark" ? "text-white bg-[#262626]" : "text-black bg-[#F0F0F0]"}`}>
                        <div className="relative p-2">
                            <img src={member.picture} alt='member-image' className='w-16 h-16 rounded-[50%]' />
                            <div className={`text-[16px] font-[500] mt-3 ${theme === "dark" ? "text-white" : "text-[#000]"}`}>{member.firstName} {member.lastName}</div>
                        </div>
                        <div className='flex justify-between'>
                            <div>
                                <div className="absolute top-2  right-2">
                                    <img src={theme === "dark" ? DotsDark : crudIcon} alt='edit-image' className='w-9 p-2 cursor-pointer' onClick={() => toggleDropdown(index)} />
                                </div>
                            </div>
                        </div>
                        <div className={`text-[16px] font-[500] mt-3 p-2 rounded-[8px] ${theme === "dark" ? "bg-[#5E5E5E]" : "bg-[#FAFAFA]"}`}>
                            <div>Living Room</div>
                            <div className="flex mt-2">
                                <img src={theme === "dark" ? emailIconDark : emailIcon} className="w-6" />
                                <span className="ml-2 text-[14px] font-[400] md:text-[16px] ">{member.email}</span>
                            </div>
                        </div>

                        {dropdownStates[index] && (
                            <div className={`absolute top-10 right-0  shadow-md w-36 rounded-lg p-2 ${theme === "dark" ? "text-white bg-[#262626]" : "bg-white"}`}>
                                <div className='cursor-pointer p-2 flex' onClick={() => { handleOpenDelete(member.email,) }} >
                                    <div className='rounded-[8px] bg-[#D2D2D240] p-2'>
                                        <img src={Delete} />
                                    </div>
                                    <span className='ml-4 mt-1'>Delete</span>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <AddMemberModal
                open={openAddMember}
                handleClose={handleCloseAdd}
                style={style1}
            />
            <RemoveMemberModal
                open={openDelete}
                handleClose={handleCloseDelete}
                style={style}
                handleDeleteMember={handleDeleteMember}
                memberId={selectedMember}
            />
        </div>
    );
}
