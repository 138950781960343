import React, { useState } from "react";
import axios from 'axios';
import styles from "../../../style";
import envelope from "../../../assets/images/envelope.svg";
import "./contactUs.css";
import CustomSnackbar from "../../CustomSnackbar";

const ContactUs = () => {

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [contactSnackbar, setContactSnackbar] = useState(false);
  const handleSend = async () => {
    try {

      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/auth/add-contact-us`,
        {
          fullName,
          email,
          message
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setContactSnackbar(true);
      setFullName('');
      setEmail('');
      setMessage('');
    } catch (err) {
      console.error("Error:", err);
    }
  }

  return (
    <section className={`${styles.paddingX} my-[80px]`}>
      <div className="flex flex-col sm:flex-row justify-center gap-[63px] ">
        <div className="w-full sm:w-1/3 md:w-1/2 flex flex-col gap-10 text-left mt-5">
          <h3
            className={`text-[#061C3D] text-[32px] sm:text-[42px] font-[600] font-Inter`}
          >
            Contact Us
          </h3>
          <p className="text-[#42526B] text-[16px] font-Inter font-[400] max-w-[536px] ">
            Have questions or need assistance? Our dedicated team is here to
            help you make the most of your Sensy32 experience.
          </p>

          <div className="flex items-center gap-5">
            <img src={envelope} alt="envelope" />
            <div className="flex flex-col">
              <span>Email us</span>
              <span>support@sensy32.io</span>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-2/3 md:w-1/2 form p-[48px] text-left flex-col ">

          <div className="flex flex-col gap-[10px] my-[16px]">
            <label htmlFor="name">Full Name</label>
            <input
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              type="text"
              id="name"
              className="input"
            />
          </div>

          <div className="flex flex-col gap-[10px] my-[16px]">
            <label htmlFor="email">Email</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              id="email"
              className="input"
            />
          </div>

          <div className="flex flex-col gap-[10px]">
            <label htmlFor="message">Message</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              type="text"
              id="message"
              rows="3"
              className="input"
            />
          </div>

          <button className="bg-[#168004] mt-[25px] px-[30px] py-[14px] text-white font-Inter font-[600] flex items-center justify-center gap-3 max-w-[186px] rounded-[8px]" onClick={handleSend}>
            <span>Send</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="15"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                d="M13.4553 4.82604L2.13121 16.1501C1.89795 16.3834 1.60106 16.5 1.24056 16.5C0.880053 16.5 0.583168 16.3834 0.349901 16.1501C0.116634 15.9168 0 15.6199 0 15.2594C0 14.8989 0.116634 14.6021 0.349901 14.3688L11.674 3.04473H2.00398C1.64347 3.04473 1.34129 2.9228 1.09742 2.67893C0.853545 2.43506 0.73161 2.13287 0.73161 1.77237C0.73161 1.41186 0.853545 1.10968 1.09742 0.865805C1.34129 0.621935 1.64347 0.5 2.00398 0.5H14.7276C15.0881 0.5 15.3903 0.621935 15.6342 0.865805C15.8781 1.10968 16 1.41186 16 1.77237V14.496C16 14.8565 15.8781 15.1587 15.6342 15.4026C15.3903 15.6465 15.0881 15.7684 14.7276 15.7684C14.3671 15.7684 14.0649 15.6465 13.8211 15.4026C13.5772 15.1587 13.4553 14.8565 13.4553 14.496V4.82604Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
      <CustomSnackbar openSnackbar={contactSnackbar}
        closeSnackbar={() => { setContactSnackbar(false) }}
        message="Your request has been sent successfully"
      />
    </section>
  );
};

export default ContactUs;
