import React, { useState } from "react";
import "./register.css";
import LoginBg from "../../assets/images/Img.png";
import logo from "../../logo.svg";
import PasswordIcon from "../../assets/icons/closed_eye.svg";
import EyeIcon from "../../assets/icons/opened_eye.svg";
import GoogleIcon from "../../assets/icons/google-logo.svg";
import { useNavigate } from "react-router";

import axios from "axios";
import CustomSnackbar from "../../components/CustomSnackbar";
export default function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [failSnackbar, setFailSnackbar] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [userWithoutFields, setUserWithoutFields] = useState(false);
  const navigate = useNavigate();
  const googleAuth = () => {
    window.open(`${process.env.REACT_APP_SERVER_URL}/auth/google`, "_self");
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleRegistration = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setFailSnackbar(true);
    } else {
      const userData = {
        firstName,
        lastName,
        email,
        password,
        themeColor: "#168004"
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/auth/register`,
          userData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 201) {
          // Registration was successful
          setSuccessSnackbar(true);
          const { user, token } = response.data;
          localStorage.setItem("user", JSON.stringify(user));
          if (!user.themeColor) {
            localStorage.setItem("theme-color", "#168004");
          } else {
            localStorage.setItem("user-color", JSON.stringify(user.themeColor));
          }
          localStorage.setItem("token", token);
          localStorage.setItem("nb_boards", 0);
          window.location.href = "/home-dashboard";
        }
        else if (response.status === 200 && response.data.message == "Email is already taken.") {
          setUserExists(true);
        }
        else if (response.status === 200 && response.data.message == "All fields are required!") {
          setUserWithoutFields(true);
        }
        else {
          console.error("Registration failed:", response.data);
          setFailSnackbar(true);
        }
      } catch (error) {
        console.error("Registration error:", error);
      }
    }
  };

  return (
    <div className="w-full min-h-screen flex">
      <div
        className="hidden sm:hidden md:hidden image-register lg:block lg:w-1/2 min-h-full flex flex-col bg-cover"
        style={{ backgroundImage: `url(${LoginBg})` }}
      >
        <div className="flex flex-col items-center justify-center h-full mr-20 text-center sm:text-center">
          <h1 className="signup-text mt-6 sm:mt-12 p-2 text-white text-2xl sm:text-3xl">
            Already have an account?
          </h1>
          <p className="signup-text-details text-sm sm:text-base">
            Log in to your account so you can continue using <br /> our customer
            experience.
          </p>
          <button
            type="submit"
            className="signUp-button mt-4 sm:mt-5 rounded-lg p-2"
            onClick={() => navigate("/login")}
          >
            Log in
          </button>
        </div>
      </div>
      <div className="lg:w-1/2 w-full h-full flex flex-col mx-auto">
        <div className="background-image-register background-register">
          <div className="flex flex-col justify-center items-center mt-20">
            <img className="lg:mt-12 mt-8" src={logo} alt="Logo" />
          </div>

          <div className="flex flex-col items-center justify-center mt-2">
            <div className="flex justify-start items-start lg:mt-8  ">
              <h1 className="login-title text-green-600 font-inter font-semibold text-[25px] sm:text-[30px] leading-6 sm:leading-8">
                Create an Account
              </h1>
            </div>

            <form
              onSubmit={handleRegistration}
              className="w-full mt-8 sm:w-full md:w-full sm:block px-12 sm:px-24 lg:px-12 space-y-4"
            >

              <div className="flex flex-col xs:flex-row gap-3">
                <div className="flex flex-col w-full xs:w-[1/2]">
                  <label htmlFor="firstname" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstname"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="form-input-register p-2  border rounded-lg mt-1"
                    placeholder="Your First Name"
                  />
                </div>
                <div className="flex flex-col  w-full xs:w-[1/2]">
                  <label htmlFor="lastname" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastname"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="form-input-register p-2 border rounded-lg mt-1"
                    placeholder="Your Last Name"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <div className="flex items-center input-container">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    className="w-full mt-1 sm:w-full md:w-full form-input-register p-2 pr-10 border rounded-lg"
                    placeholder="Enter your email"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <div className="flex items-center input-container">
                  <input
                    type={showPassword ? "type" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    className="w-full mt-1 sm:w-full md:w-full form-input-register p-2 pr-10 border rounded-lg"
                    placeholder="Enter your password"
                  />
                  <div className="icon-container absolute inset-y-0 right-0 flex items-center pr-3">
                    <img
                      src={showPassword ? EyeIcon : PasswordIcon}
                      alt="Password Icon"
                      onClick={togglePasswordVisibility}
                      className="icon-auth cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div>
                <label htmlFor="confirm-password" className="form-label">
                  Confirm Password
                </label>
                <div className="flex items-center input-container">
                  <input
                    type={showPassword ? "type" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    id="confirm-password"
                    className="w-full mt-1 sm:w-full md:w-full form-input-register p-2 pr-10 border rounded-lg"
                    placeholder="Enter your password again"
                  />
                  <div className="icon-container absolute inset-y-0 right-0 flex items-center pr-3">
                    <img
                      src={showPassword ? EyeIcon : PasswordIcon}
                      alt="Password Icon"
                      onClick={togglePasswordVisibility}
                      className="icon-auth cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="rememberMe"
                  className="form-checkbox h-4 w-4"
                />
                <label htmlFor="rememberMe" className="form-label ml-2">
                  Remember Me
                </label>
              </div>
              <button
                type="submit"
                className="form-input-register mt-0 login-button rounded-lg p-2"
              >
                Sign up
              </button>
              <div className="flex justify-center items-center form-input-register lg:hidden ">
                <div>
                  Already have an account? <span> </span>
                  <a href="/login" className="forgot-password">
                    Login
                  </a>
                </div>
              </div>
            </form>

            <div className=" flex my-5  items-center justify-center">
              <p className="social-media w-full text-center">
                Or Sign up using
              </p>
            </div>

            <div className="w-full px-12 sm:px-24 lg:px-12 flex items-center justify-center">

              <button
                type="submit"
                onClick={googleAuth}
                className="flex items-center gap-2 justify-center mb-5  w-full  text-black md:flex bg-white rounded-[10px] sm:text-[16px] text-[15px] font-[500] px-[30px] py-[8px] max-h-[55px]transition-all duration-75 active:scale-90"
                style={{
                  boxShadow: "0px 1.994px 5.982px 0px rgba(19, 18, 66, 0.07)",
                  border: "0.997px solid var(--Neutral-300, #EFF0F6)",
                }}
              >
                <img
                  src={GoogleIcon}
                  alt="Google"
                  height="33"
                  width="33"
                  className="flex items-center py-2 p-1 rounded-lg"
                />
                <span>Continue with Google</span>
              </button>

            </div>
          </div>
        </div>
      </div>
      <CustomSnackbar
        openSnackbar={failSnackbar}
        closeSnackbar={() => {
          setFailSnackbar(false);
        }}
        message="Registration failed! Please try again."
      />
      <CustomSnackbar
        openSnackbar={successSnackbar}
        closeSnackbar={() => {
          setSuccessSnackbar(false);
        }}
        message="Registration successful!"
      />

      <CustomSnackbar
        openSnackbar={userExists}
        closeSnackbar={() => {
          setUserExists(false);
        }}
        message="Cannot register! User already exists."
      />

      <CustomSnackbar
        openSnackbar={userWithoutFields}
        closeSnackbar={() => {
          setUserWithoutFields(false);
        }}
        message="Cannot register! All fields are required."
      />
    </div>
  );
}
