import React from "react";
import styles, { typo } from "../../../style";
import ellipse_left from "../../../assets/images/ellipse-left.svg";
import "./featureUses.css";
import { useNavigate } from "react-router";

const FeatureUses = () => {
  const navigate = useNavigate();

  const features = [
    {
      header: "Compact Powerhouse: The Art of Miniaturization",
      paragraph:
        "Introducing Sensy32, not just a sensory marvel but also a feat in miniaturization. At a mere 43x34mm, this board packs a punch in a remarkably small footprint. Designed for space-conscious projects, Sensy32 proves that great things indeed come in small packages. Its compact size makes it ideal for a wide range of applications, from wearable technology to space-limited IoT devices, without compromising on functionality.",
    },
    {
      header: "Advanced Engineering: A Symphony on a 6-Layer PCB",
      paragraph:
        "Crafted with precision, Sensy32 is a testament to advanced PCB design. The 6-layer PCB is more than just a structural base; it’s the canvas where technology meets art. This intricate design ensures optimal performance and reliability of each sensor, providing a harmonious balance between power and elegance. The multi-layer approach not only enhances the signal integrity but also accommodates the myriad of sensors in a neatly organized, efficient layout.",
    },
    {
      header: "Luxury in Every Layer: The Premium Choice",
      paragraph:
        "Sensy32 is not just a development board; it's a statement of luxury in the world of electronics. Designed for those who seek the best, it represents a blend of sophisticated design and cutting-edge technology. Every aspect of Sensy32 speaks of quality and precision, from its sleek aesthetics to its robust performance. This premium board is for creators who value excellence and wish to save time without sacrificing elegance and efficiency.",
    },
    {
      header: "All-in-One Sensory Solution: Save Time, Elevate Experience",
      paragraph:
        "With Sensy32, experience the convenience of having a full spectrum of sensors on a single board. This all-in-one solution is a game-changer for developers and innovators. By integrating multiple sensors in one compact unit, Sensy32 saves you the time and hassle of sourcing and assembling individual components. It's not just about efficiency; it's about elevating your project's experience to a level where luxury meets practicality.",
    },
  ];
  return (
    <section
      className={`${styles.paddingX} relative bg-[#FAFDFA] flex flex-col items-center pt-[50px] pb-[40px]`}
    >
      <div>
        <img
          src={ellipse_left}
          alt="Header"
          className="left-0 absolute -z-10 "
        />
      </div>

      <h2 className={`${typo.h3} mb-[80px]`}>Features That Use Sensy32</h2>

      <div className="flex items-center justify-center flex-wrap gap-[76px]">
        {/* CARD */}
        {features.map((card, index) => {
          return (
            <div
              key={index}
              className="f-card bg-white rounded-[16px] text-left flex flex-1 sm:min-w-[500px]  p-[24px] gap-[24px]"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  className="feature-icon"
                >
                  <path
                    d="M13.6097 19.6146L9.69712 15.7021C9.46394 15.4689 9.17083 15.3496 8.81779 15.3442C8.46475 15.3387 8.16624 15.458 7.92225 15.7021C7.67826 15.946 7.55627 16.2419 7.55627 16.5895C7.55627 16.9371 7.67826 17.2329 7.92225 17.4769L12.5441 22.0988C12.8486 22.4032 13.2038 22.5554 13.6097 22.5554C14.0156 22.5554 14.3708 22.4032 14.6753 22.0988L24.0453 12.7287C24.2785 12.4955 24.3978 12.2024 24.4032 11.8494C24.4086 11.4963 24.2893 11.1978 24.0453 10.9538C23.8013 10.7099 23.5055 10.5879 23.1579 10.5879C22.8103 10.5879 22.5145 10.7099 22.2705 10.9538L13.6097 19.6146ZM16.0028 32C13.7899 32 11.7098 31.5801 9.76264 30.7402C7.81543 29.9003 6.12164 28.7606 4.68128 27.3208C3.24088 25.8811 2.10057 24.188 1.26034 22.2417C0.420114 20.2954 0 18.2158 0 16.0028C0 13.7899 0.419931 11.7098 1.25979 9.76264C2.09965 7.81543 3.23945 6.12165 4.67917 4.68128C6.11892 3.24088 7.81196 2.10057 9.7583 1.26034C11.7046 0.420115 13.7842 0 15.9972 0C18.2101 0 20.2902 0.419933 22.2374 1.25979C24.1846 2.09966 25.8784 3.23945 27.3187 4.67917C28.7591 6.11892 29.8994 7.81197 30.7397 9.7583C31.5799 11.7046 32 13.7842 32 15.9972C32 18.2101 31.5801 20.2902 30.7402 22.2374C29.9003 24.1846 28.7606 25.8784 27.3208 27.3187C25.8811 28.7591 24.188 29.8994 22.2417 30.7397C20.2954 31.5799 18.2158 32 16.0028 32Z"
                    fill="#168004"
                  />
                </svg>
              </div>
              <div className="flex flex-col feature-content">
                <h4 className="text-[#343434] text-[18px] font-Inter font-[700] mb-[10px]">
                  {card.header}
                </h4>
                <p className="text-[#343434] text-[15px] font-Inter font-[500]">
                  {card.paragraph}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <button className="bg-[#168004] mt-[25px] px-[30px] py-[14px] text-white font-Inter font-[600] flex items-center justify-center gap-3 max-w-[186px] rounded-[8px]"   onClick={() => navigate("/register")}>
        <span>Try Now</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <path
            d="M13.4553 4.82604L2.13121 16.1501C1.89795 16.3834 1.60106 16.5 1.24056 16.5C0.880053 16.5 0.583168 16.3834 0.349901 16.1501C0.116634 15.9168 0 15.6199 0 15.2594C0 14.8989 0.116634 14.6021 0.349901 14.3688L11.674 3.04473H2.00398C1.64347 3.04473 1.34129 2.9228 1.09742 2.67893C0.853545 2.43506 0.73161 2.13287 0.73161 1.77237C0.73161 1.41186 0.853545 1.10968 1.09742 0.865805C1.34129 0.621935 1.64347 0.5 2.00398 0.5H14.7276C15.0881 0.5 15.3903 0.621935 15.6342 0.865805C15.8781 1.10968 16 1.41186 16 1.77237V14.496C16 14.8565 15.8781 15.1587 15.6342 15.4026C15.3903 15.6465 15.0881 15.7684 14.7276 15.7684C14.3671 15.7684 14.0649 15.6465 13.8211 15.4026C13.5772 15.1587 13.4553 14.8565 13.4553 14.496V4.82604Z"
            fill="white"
          />
        </svg>
      </button>
    </section>
  );
};

export default FeatureUses;
