import React, { createContext, useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import SecondHeader from "../components/SecondHeader";
import { ThemeContext } from "../state/ThemeContext";
import { useUser } from "../state/UserContext";
import { useLocation } from 'react-router-dom';

export const MenuContext = createContext();

const Layout = ({ page }) => {
  const [isPhoneScreen, setIsPhoneScreen] = useState(window.innerWidth <= 767);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { userData, setUser } = useUser();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const myParam = searchParams.get('data');

    if (myParam) {
      const { user, token } = JSON.parse(myParam);
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
    }
  }, [location.search]);
  useEffect(() => {
    const mediaQueryPhone = window.matchMedia("(max-width: 767px)");
    const handleResizePhone = (event) => {
      setIsPhoneScreen(event.matches);
    };

    mediaQueryPhone.addListener(handleResizePhone);
    // Initial check
    setIsPhoneScreen(mediaQueryPhone.matches);

    // Remove the listener when the component unmounts
    return () => {
      mediaQueryPhone.removeListener(handleResizePhone);
    };
  }, []);
  return (
    //todo fix the two headers for the two different dashboards
    <div className={`flex h-screen dark:bg-dark1 ${theme}`}>
      <MenuContext.Provider value={[mobileMenuOpen, setMobileMenuOpen]}>
        {(mobileMenuOpen || !isPhoneScreen) && <Sidebar />}
        <div className="main-content overflow-y-auto flex-1 w-[100%]">
          {/* <Header />  */}
          <SecondHeader />
          <hr className={`${theme === "dark" ? "text-black" : "text-white"
            }`} />
          {page}
        </div>
      </MenuContext.Provider>
    </div>
  );
};

export default Layout;
