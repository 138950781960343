import React from "react";
import { Modal, Box } from "@mui/material";
import { ThemeContext } from "../../../state/ThemeContext";
import { useContext } from "react";
import trash from "../../../assets/images/trash-icon.svg";
const RemoveMemberModal = ({
    open,
    handleClose,
    style,
    handleDeleteMember,
    memberId,
}) => {
    const theme = useContext(ThemeContext).theme;

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal"
        >
            <Box sx={style}>
                <div className="flex justify-between items-center mb-2">
                    <div className="flex">
                        <img src={trash} />
                        <h3
                            className={`font-[700] text-[24px] mt-2  ml-3 ${theme === "dark" ? "text-white" : "text-black"
                                }`}
                        >
                            Delete
                        </h3>
                    </div>
                    <button type="button" onClick={handleClose}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="27"
                            height="24"
                            viewBox="0 0 27 24"
                            fill="none"
                        >
                            <path
                                d="M20.25 5.93091L6.75 17.3185"
                                stroke="#525256"
                                strokeWidth="2.25"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M6.75 5.93091L20.25 17.3185"
                                stroke="#525256"
                                strokeWidth="2.25"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>
                <div>
                    <p
                        className={`text-[20px]  ${theme === "dark" ? "text-[#858580]" : "text-[#858585]"
                            }`}
                    >
                        Are you sure you want to delete this board ?   </p>
                </div>
                <div className="flex justify-end mt-[20px] mb-[45px]">
                    <div className="flex flex-col lg:justify-end lg:items-end lg:flex-row gap-[16px] modal-cancel w-full">
                        <button
                            className="bg-[#D9D9D9] rounded-[10px] px-[16px] py-[10px] w-full text-black"
                            type="button"
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="text-white rounded-[10px] px-[16px] py-[10px] w-full "
                            type="button"
                            onClick={() => {
                                handleDeleteMember(memberId);
                            }}
                            style={{
                                backgroundColor: "#D92D20"
                            }}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default RemoveMemberModal;
