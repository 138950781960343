import React, { useContext, useEffect, useState } from 'react';
import { Modal, Box } from '@mui/material';
import { useUser } from '../../../state/UserContext';
import AddBoardModal from '../boards/AddBoardModal';
import { ThemeContext } from '../../../state/ThemeContext';

const AddBoardWarningModal = ({ open, handleClose, style, onBoardAdded }) => {
    const { userData } = useUser();
    const theme = useContext(ThemeContext).theme;
    const [selectedImage, setSelectedImage] = useState(null);
    const [openAddBoard, setOpenAddBoard] = useState(false);
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCloseAddBoard = async () => {
        setOpenAddBoard(false);
        handleClose();
        if (onBoardAdded) {
            onBoardAdded(true);
        }
    };

    useEffect(() => {
        if (openAddBoard && onBoardAdded) {
            onBoardAdded(false);
        }
    }, [openAddBoard, onBoardAdded]);
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal"
        >
            <Box sx={style} className="flex flex-col p-4">
                <div className="flex justify-between items-center mb-2">
                    <div className={`font-semibold text-lg  ${theme === "dark" ? "text-white" : "text-black"}`}>
                        Please add a board first.
                    </div>
                    <button
                        className="bg-dark_green text-white rounded-md text-base font-semibold py-2.5 px-6 transition-all duration-75 active:scale-90"
                        type="button"
                        onClick={() => {
                            setOpenAddBoard(true);
                        }}
                        style={{ backgroundColor: localStorage.getItem("theme-color") || userData.themeColor }}
                    >
                        Add Board
                    </button>
                </div>
                <AddBoardModal open={openAddBoard}
                    handleClose={handleCloseAddBoard}
                    style={style}
                    handleImageUpload={handleImageUpload}
                    selectedImage={selectedImage}
                />
            </Box>
        </Modal>
    );
};

export default AddBoardWarningModal;
