import React, { useState } from "react";
import { Modal, Box } from "@mui/material";
import Avatars from '../../../assets/images/Avatar-group.svg';
import trash from '../../../assets/images/delete-member.svg';

import { removeUserFromDashboard } from "../../../services/dashboardService";
import CustomSnackbar from "../../CustomSnackbar";

const ListMembersModal = ({ open, handleClose, style, dashboardId, members, fetchMembers }) => {
    const [addBoardSnackbar, setAddBoardSnackbar] = useState(false);
    const [removeMember, setRemoveMember] = useState(false);
    const handleCloseModal = () => {
        handleClose();
    };
    const handleDeleteMember = async (email, dashboardId) => {
        try {
            await removeUserFromDashboard(email, dashboardId);
            fetchMembers();
            handleCloseModal();
            setRemoveMember(true);
        } catch (error) {
            console.error("Error deleting member:", error);
        }
    };
    return (
        <div>
            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal"
            >
                <Box sx={style}>
                    {/* Modal content */}
                    <div className="flex justify-end items-end">
                        <button type="button" onClick={handleClose}>
                            {/* Close button */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="27"
                                height="24"
                                viewBox="0 0 27 24"
                                fill="none"
                            >
                                <path
                                    d="M20.25 5.93091L6.75 17.3185"
                                    stroke="#525256"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6.75 5.93091L20.25 17.3185"
                                    stroke="#525256"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="flex justify-center items-center mb-2">
                        <div className="">
                            <img src={Avatars} className="w-40 ml-5" />
                            <h3 className="text-dark_black font-[700] text-[28px] flex justify-center items-center mt-4">
                                Members
                            </h3>
                        </div>
                    </div>
                    <div className="mb-4">
                        {members.map((member, index) => (
                            <div key={index} className="flex flex-col gap-4">
                                <div className="flex justify-between mt-4">
                                    <div className="flex">
                                        <div>
                                            <img src={member.picture} className="w-10 h-10 rounded-[50%]" />
                                        </div>
                                        <div className="flex flex-col ml-2">
                                            <span className="text-[#344054] text-[14px] font-[600]">{member.firstName}</span>
                                            <span className="text-[#475467] text-[14px] font-[400]">{member.email}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={trash} className="w-5 h-5 cursor-pointer" onClick={() => handleDeleteMember(member.email, dashboardId)} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Box>
            </Modal>
            <CustomSnackbar
                openSnackbar={addBoardSnackbar}
                closeSnackbar={() => {
                    setAddBoardSnackbar(false);
                }}
                message="The team member has been invited successfully"
            />
            <CustomSnackbar
                openSnackbar={removeMember}
                closeSnackbar={() => {
                    setRemoveMember(false);
                }}
                message="The member has been deleted successfully"
            />
        </div>
    );
};

export default ListMembersModal;
