import React, { useContext, useEffect, useState } from "react";
import { Modal, Box, FormControlLabel } from "@mui/material";
import closeIcon from "../../../assets/icons/close_icon.svg";
import axios from "axios";
import { useUser } from "../../../state/UserContext";
import { ThemeContext } from "../../../state/ThemeContext";
import IOSSwitch from "../../IosSwitch";
import CustomSnackbar from "../../CustomSnackbar";
const AddSensorModal = ({
  open,
  handleClose,
  style,
  userBoards,
  defaultBoardId,
}) => {
  const { userData } = useUser();
  const [isRealtime, setIsRealtime] = useState(false);
  const [isSevere, setIsSevere] = useState(false);
  const [isHistorical, setIsHistorical] = useState(false);
  const [addSensorSnackbar, setAddSensorSnackbar] = useState(false);
  const theme = useContext(ThemeContext).theme;
  const [errors, setErrors] = useState({});

  const sensorsOptions = [
    "temperature",
    "humidity",
    "pressure",
    "altitude",
    "Accelerometer",
    "Orientation",
    "UV",
    "Light",
    "Motion",
  ];
  const [formData, setFormData] = useState({
    sensor_type: sensorsOptions[0].toLowerCase().replace(/\s+/g, "-"),
    name: "",
    unit: "",
    description: "",
    code_api: "",
    is_historical: false,
    is_realtime: false,
    is_severe: false,
  });
  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      board: defaultBoardId,
    }));
  }, [defaultBoardId]);
  const handleCloseModal = () => {
    handleClose();
  };
  const addSensor = async (sensor) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token is missing");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + token

      };

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/sensors/`,
        { sensor },
        { headers }
      );
      if (response.status == 201) {
        handleCloseModal();
        setAddSensorSnackbar(true);
      }
    } catch (error) {
      console.error("Error adding sensor", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      is_realtime: isRealtime,
      is_severe: isSevere,
      is_historical: isHistorical,
    }));
  }, [isRealtime, isSevere, isHistorical]);

  const handleSave = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!formData.name) {
      validationErrors.name = "Name field is required";
    }
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      addSensor(formData);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Box sx={style}>
          <div className="flex justify-between items-center mb-2">
            <h3
              className={`font-[700] text-[28px]  ${theme === "dark" ? "text-white" : "text-black"
                } `}
            >
              Add sensor
            </h3>
            <button type="button" onClick={handleClose}>
              <img src={closeIcon} height="14" width="14" />

            </button>
          </div>
          <div className="bg-[#EFF0EF] my-[15px]" style={{ height: "2px" }} />
          <div>
            <form className="flex flex-col">
              <label
                htmlFor="type"
                className={`my-3 text-[15px] font-[500] ${theme === "dark" ? "text-white" : "text-black"
                  }`}
              >
                Type
              </label>
              <select
                name="sensor_type"
                id="sensor_type"
                form="sensor_type"
                className={`rounded-[8px]  mb-[10px] px-3 focus:outline-none h-[54px] ${theme === "dark"
                  ? "bg-[#4A4A4A]"
                  : "bg-white border border-[#D0D5DD]"
                  } `}
                value={formData.sensor_type}
                onChange={handleChange}
              >
                {sensorsOptions.map((option, index) => (
                  <option
                    key={index}
                    value={option.toLowerCase().replace(/\s+/g, "-")}
                  >
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </option>
                ))}

              </select>
              <label
                htmlFor="name"
                className={`my-3 text-[15px] font-[500]  ${theme === "dark" ? "text-white" : "text-black"
                  }`}
              >
                Name
              </label>
              <input
                label="Name"
                name="name"
                fullWidth
                placeholder="Enter sensors name"
                value={formData.name}
                onChange={handleChange}
                className={`rounded-[8px]  mb-[10px] px-3 focus:outline-none h-[54px] ${theme === "dark"
                  ? "bg-[#4A4A4A]"
                  : "bg-white border border-[#D0D5DD]"
                  } `}
              />
              {errors.name && <p className="text-red-500">{errors.name}</p>}
              <label
                htmlFor="desc"
                className={`my-3 text-[15px] font-[500] ${theme === "dark" ? "text-white" : "text-black"
                  }`}
              >
                Description
              </label>
              <textarea
                label="Description"
                name="description"
                sensors="3"
                fullWidth
                placeholder="Enter description"
                value={formData.description}
                onChange={handleChange}
                className={`rounded-[8px]  mb-[10px] px-3 focus:outline-none h-[54px] ${theme === "dark"
                  ? "bg-[#4A4A4A]"
                  : "bg-white border border-[#D0D5DD]"
                  } `}
              ></textarea>
              <label
                htmlFor="board"
                className={`my-3 text-[15px] font-[500] ${theme === "dark" ? "text-white" : "text-black"
                  }`}
              >
                Connect to
              </label>
              <select
                name="board"
                id="board"
                form="board"
                className={`rounded-[8px]  mb-[10px] px-3 focus:outline-none h-[54px] ${theme === "dark"
                  ? "bg-[#4A4A4A]"
                  : "bg-white border border-[#D0D5DD]"
                  } `}
                value={formData.board}
                onChange={handleChange}
              >
                {userBoards.map((board) => (
                  <option key={board.id} value={board.id}>
                    {board.name}
                  </option>
                ))}
              </select>
              {errors.board && <p className="text-red-500">{errors.board}</p>}

              <div
                className={`flex flex-wrap pt-8 ${theme === "dark" ? "text-white" : "text-black"
                  }`}
              >
                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={!!isRealtime}
                      onChange={() => setIsRealtime((prevCheck) => !prevCheck)}
                    />
                  }
                  label="Realtime data"
                  className="mb-2"
                />

                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={!!isSevere}
                      onChange={() => setIsSevere((prevCheck) => !prevCheck)}
                    />
                  }
                  label="Critical sensor"
                  className="mb-2"
                />

                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={!!isHistorical}
                      onChange={() =>
                        setIsHistorical((prevCheck) => !prevCheck)
                      }
                    />
                  }
                  label="Save history"
                  className="mb-2"
                />
              </div>

              <div className="flex flex-wrap justify-end mt-[30px] mb-[45px]">
                <div className="flex flex-wrap gap-[16px] modal-cancel w-full lg:w-auto">
                  <button
                    className="bg-[#D9D9D9] rounded-[10px] px-[16px] py-[10px] w-full lg:w-28 text-black"
                    type="button"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    className=" text-white rounded-[10px] px-[16px] py-[10px] w-full lg:w-28"
                    type="button"
                    onClick={(e) => {
                      handleSave(e);
                    }}
                    style={{
                      backgroundColor:
                        localStorage.getItem("theme-color") ||
                        userData.themeColor,
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      <CustomSnackbar
        openSnackbar={addSensorSnackbar}
        closeSnackbar={() => {
          setAddSensorSnackbar(false);
        }}
        message="Sensor added successfully"
      />
    </div>
  );
};

export default AddSensorModal;
